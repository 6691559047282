import posthog from 'posthog-js'; // new
import { useEffect } from "react";

export default function PosthogCapture() {
    useEffect(() => {
        if (!/^((?!staging\.).*\.augmia\.io)$/.test(window.location.hostname)) {
            posthog.opt_out_capturing();
        }

    }, [])
    return <></>
}