import {
    Box,
    Button,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { MdCameraEnhance } from 'react-icons/md';
import { usePermissions } from '../hooks/use-permission';

interface CameraAccessRequestProps {
    isOpen: boolean;
    onClose: () => void;
    onAllow: (state: 'granted' | 'denied') => void;
}

export function CameraAccessRequest({ isOpen, onClose, onAllow }: CameraAccessRequestProps) {
    const { t } = useTranslation('app');
    const { state, request } = usePermissions('camera');

    const handleAllow = async () => {
        const result = await request();
        onAllow(result);
        onClose();
    };

    const handleDeny = () => {
        onAllow('denied');
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
            <ModalOverlay />
            <ModalContent bg="#1A202C" color="white" borderRadius="xl">
                <ModalHeader>
                    <HStack spacing={3}>
                        <Text fontSize="2xl" fontWeight="bold">{t('cameraAccess.title')}</Text>
                    </HStack>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={6} align="stretch">
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bg="whiteAlpha.200"
                            borderRadius="xl"
                            p={8}
                        >
                            <Icon as={MdCameraEnhance} boxSize={24} color="blue.400" />
                        </Box>
                        <Text>{t('cameraAccess.description')}</Text>
                        <VStack align="start" spacing={3} bg="whiteAlpha.100" p={4} borderRadius="md">
                            <HStack>
                                <Icon as={FaCheckCircle} color="green.400" />
                                <Text>{t('cameraAccess.benefit1')}</Text>
                            </HStack>
                            <HStack>
                                <Icon as={FaCheckCircle} color="green.400" />
                                <Text>{t('cameraAccess.benefit2')}</Text>
                            </HStack>
                            <HStack>
                                <Icon as={FaTimesCircle} color="red.400" />
                                <Text>{t('cameraAccess.limitation')}</Text>
                            </HStack>
                        </VStack>
                        <Text fontSize="sm" color="gray.400">{t('cameraAccess.privacyNote')}</Text>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <HStack spacing={4} width="100%">
                        <Button
                            onClick={handleAllow}
                            flex={1}
                            color='#000'
                            background='#9E8CF2 20%'
                            _hover={{ background: '#9E8CF2 20%', color: '#000' }}
                        >
                            {t('cameraAccess.allow')}
                        </Button>
                        <Button variant="outline" onClick={handleDeny} flex={1}>
                            {t('cameraAccess.deny')}
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

