export default {
    home: {
        heading: 'Vítejte v',
        headingName: 'Augmia',
        scanCode: 'Skenovat kód',
        orderText: 'Stále od nás nemáte produkt? Objednejte',
        orderLink: 'zde',
        addToHomeText: "Abyste si přidal naši aplikaci do telefonu, tak stiskněte ve vašem prohlížečí tlačítko sdílet a vyberte 'Přidat na plochu.'",
        addToHomeButton: 'Přidat na plochu',
        qrCodeScanningStates: {
            searching: 'HLEDÁM QR KÓD',
            searchingWithHint: 'PŘIBLIŽTE SE',
            found: 'QR KÓD NALEZEN',
        },
        exitScanner: 'Opustit Scanner',
    },
    selectEffect: {
        heading: 'Výběr animace',
        effects: {
            hearts: 'Srdce',
            butterflies: 'Motýli',
            sparks: 'Jiskry',
            empty: 'Bez efektu',
        },
    },
    startButton: {
        text: 'Start',
    },
    viewer: {
        exitApp: 'Odejít',
        canvasDetectionStates: {
            searching: 'Hledám Cíl',
            searchingWithHint: 'Namiřte kameru na Cíl ',
            found: 'Cíl NALEZEN',
            empty: 'PRÁZDNÝ',
        },
    },
    "cameraAccess": {
        "title": "Vyžadován přístup ke kameře",
        "description": "Aplikace Augmia potřebuje přístup ke kameře, aby umožnila AR funkce. Kamera bude používána pouze během aktivního používání aplikace.",
        "benefit1": "Zažijte pohlcující AR obsah",
        "benefit2": "Interagujte s virtuálními objekty v reálném čase",
        "limitation": "Kamera se nepoužívá, když je aplikace zavřená",
        "privacyNote": "Respektujeme vaše soukromí. Data z kamery jsou zpracovávána na zařízení a nikdy nejsou ukládána ani sdílena.",
        "allow": "Povolit přístup",
        "deny": "Teď ne",
        "denied": "Vyžadován přístup ke kameře",
        "deniedDescription": "AR funkce nejsou dostupné bez přístupu ke kameře. Můžete to změnit v nastavení.",
        "info": "Pro použití AR funkcí je potřeba povolit přístup ke kameře."
    }
} as const;
