import { useEffect, useState } from 'react';

type SupportedPermissionName = 'camera' | 'microphone' | 'geolocation';
type PermissionState = 'prompt' | 'granted' | 'denied';

export function usePermissions(permissionName: SupportedPermissionName, needRequest: boolean = false) {
    const [permissionState, setPermissionState] = useState<PermissionState>('prompt');

    useEffect(() => {
        if (!needRequest || permissionState !== 'prompt') return;

        let permissionStatus: PermissionStatus;

        const handleChange = function (this: PermissionStatus) {
            setPermissionState(this.state);
        };

        const queryPermission = async () => {
            try {
                // @ts-ignore: TS doesn't recognize 'camera' as a valid PermissionName
                permissionStatus = await navigator.permissions.query({ name: permissionName });
                permissionStatus.addEventListener('change', handleChange);
                setPermissionState(permissionStatus.state);
            } catch (error) {
                console.error('Error querying permission:', error);
                setPermissionState('prompt');
            }
        };

        const requestPermission = async () => {
            try {
                // @ts-ignore: TS doesn't recognize 'camera' as a valid PermissionName
                const result = await navigator.permissions.request({ name: permissionName });
                setPermissionState(result.state);
            } catch (error) {
                console.error('Error requesting permission:', error);
                setPermissionState('prompt');
            }
        };

        if ('permissions' in navigator) {
            if ('query' in navigator.permissions) {
                queryPermission();
            } else if ('request' in navigator.permissions) {
                requestPermission();
            } else {
                setPermissionState('granted');
            }
        } else {
            setPermissionState('granted');
        }

        return () => {
            if (permissionStatus) {
                permissionStatus.removeEventListener('change', handleChange);
            }
        };
    }, [permissionName, needRequest, permissionState]);

    const request = async (): Promise<PermissionState> => {
        if ('permissions' in navigator && 'request' in navigator.permissions) {
            try {
                // @ts-ignore: TS doesn't recognize 'camera' as a valid PermissionName
                const result = await navigator.permissions.request({ name: permissionName });
                setPermissionState(result.state);
                return result.state;
            } catch (error) {
                console.error('Error requesting permission:', error);
                return 'denied';
            }
        } else {
            return requestLegacy();
        }
    };

    const requestLegacy = async (): Promise<PermissionState> => {
        if (permissionName === 'camera') {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                stream.getTracks().forEach(track => track.stop());
                setPermissionState('granted');
                return 'granted';
            } catch (error) {
                console.error('Error requesting camera access:', error);
                setPermissionState('denied');
                return 'denied';
            }
        }
        return 'denied';
    };

    return { state: permissionState, request };
}

