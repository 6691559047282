import { Avatar, Heading, HStack, Link, Text, useToast, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import AppLayout from '../../../../components/AppLayout';
import { CameraAccessRequest } from '../../../../components/CameraAccessDialogRequest';
import PrimaryButton from '../../../../components/PrimaryButton';
import { usePermissions } from '../../../../hooks/use-permission';

export default function StartPage({ arApps, onStart }) {
    const [query] = useSearchParams();
    const { t } = useTranslation('app');
    const [showCameraAccessRequest, setShowCameraAccessRequest] = useState(false);
    const toast = useToast();
    const { state: cameraPermissionState } = usePermissions('camera');

    const textSource = query.get('isCollection') ? arApps[0].collection : arApps[0];
    const { author = {} } = textSource;

    useEffect(() => {
        if (cameraPermissionState === 'granted') {
            handleStart();
        }
    }, [cameraPermissionState]);

    const handleCameraPermission = (state: 'granted' | 'denied') => {
        if (state === 'granted') {
            handleStart();
        } else {
            toast({
                title: t('cameraAccess.denied'),
                description: t('cameraAccess.deniedDescription'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const unlockAutoplay = async () => {
        const videos = document.querySelectorAll('video');
        const playPromises = [];
        videos.forEach(video => {
            if (video.id !== "video") {
                video.muted = true;
                const playPromise = video.play();
                if (playPromise !== undefined) {
                    playPromises.push(
                        playPromise
                            .then(() => {
                                setTimeout(() => {
                                    video.pause();
                                    video.currentTime = 0;
                                    video.muted = false;
                                }, 100);
                            })
                            .catch(error => {
                                console.error('Error playing video:', error);
                            })
                    );
                }
            }
        });

        await Promise.all(playPromises);
    };

    const handleStart = async () => {
        await unlockAutoplay();
        if (onStart) {
            onStart();
        }
    };

    const handleStartButtonClick = () => {
        if (cameraPermissionState === 'granted') {
            handleStart();
        } else {
            setShowCameraAccessRequest(true);
        }
    };

    return (
        <AppLayout gridTemplateRows="50px 2fr 100px">
            <VStack gap="2.5rem">
                <Heading as="h2" color="#FFF8ED" fontWeight="600" marginBottom="10px">
                    {textSource.title}
                </Heading>
                <Text color="white">{textSource.description}</Text>
                <VStack spacing={1} align="center">
                    <Text color="white">Made with ❤️ by</Text>
                    <HStack spacing={3} align="center">
                        <Avatar name={author.name} src={``} size="sm" />
                        <Link href="/detail-page" color="white">
                            {author.name}
                        </Link>
                    </HStack>
                </VStack>
                <VStack>
                    <PrimaryButton buttonHandler={handleStartButtonClick}>
                        {t('startButton.text')}
                    </PrimaryButton>
                    <Text color="white">{t('cameraAccess.info')}</Text>
                </VStack>
            </VStack>
            <CameraAccessRequest
                isOpen={showCameraAccessRequest}
                onClose={() => setShowCameraAccessRequest(false)}
                onAllow={handleCameraPermission}
            />
        </AppLayout>
    );
}

