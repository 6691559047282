export default {
    home: {
        heading: 'Welcome to',
        headingName: 'Augmia',
        scanCode: 'Scan Code',
        orderText: 'If you don’t have your Magic product yet. you can order one',
        orderLink: 'here',
        addToHomeText: "To add this website to your home screen, tap the share button and select 'Add to Home Screen'.",
        addToHomeButton: 'Add to Home Screen',
        qrCodeScanningStates: {
            searching: 'LOOKING FOR QR CODE...',
            searchingWithHint: 'MOVE CLOSER',
            found: 'QR CODE FOUND',
        },
        exitScanner: 'Exit Scanner',
    },
    selectEffect: {
        heading: 'Select Animation',
        effects: {
            hearts: 'Hearts',
            butterflies: 'Butterflies',
            sparks: 'Sparks',
            empty: 'Empty',
        },
    },
    startButton: {
        text: 'Start',
    },
    viewer: {
        exitApp: 'Exit App',
        canvasDetectionStates: {
            searching: 'Looking for Target',
            searchingWithHint: 'Capture Entire Target',
            found: 'Target FOUND',
            empty: 'EMPTY',
        },
    },
    "cameraAccess": {
        "title": "Camera Access Required",
        "description": "Augmia needs access to your camera to enable AR features. Your camera will only be used while you're actively using the app.",
        "benefit1": "Experience immersive AR content",
        "benefit2": "Interact with virtual objects in real-time",
        "limitation": "Camera is not used when the app is closed",
        "privacyNote": "We respect your privacy. Camera data is processed on-device and is never stored or shared.",
        "allow": "Allow Camera",
        "deny": "Not Now",
        "denied": "Camera Access Required",
        "deniedDescription": "AR features are not available without camera access. You can change this in your settings.",
        "info": "You will need to allow camera access to use AR features."
    }
} as const;
